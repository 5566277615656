import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const DIARY_API_PATH = '/diary'

export const DiaryLogic = {
    async getDiary(diaryName, params) {
        let result = await ClientService.sendRequest(RequestMethod.GET, DIARY_API_PATH + '/' + diaryName, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async getPatientDiary(diaryName, patientId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            DIARY_API_PATH + '/' + diaryName + '/' + patientId,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async addDiaryEntry(diaryName, entry, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            DIARY_API_PATH + '/' + diaryName,
            params,
            entry
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async deleteDiaryEntry(diaryName, entryid, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.DELETE,
            DIARY_API_PATH + '/' + diaryName + "/" + entryid,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async deleteUserDiaryEntry(diaryName, userId, entryid, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.DELETE,
            DIARY_API_PATH + '/' + diaryName + "/" + userId + "/" + entryid,
            params
        );
        return await RequestResponseLogic.checkRequestResponse(result);
    },
}